
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import draggable from "vuedraggable";

// console.log(Sortable);

// import Vue from "vue";
// Vue.defineComponent("Sortable", Sortable);

import _ from "lodash";
import helpers from "@/helpers/global";

// let loadingEl: any;

export default defineComponent({
  components: {
    draggable,
  },
  name: "SetupValues&Traits",
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    error(error) {
      if (error) {
        ElMessage.error(error);
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 3000);
      }
    },
    // loading(value) {
    //   if (value) {
    //     loadingEl = ElLoading.service({
    //       lock: true,
    //       text: "Login..",
    //       background: "#ffffff90",
    //     });
    //   } else {
    //     loadingEl.close();
    //   }
    // },
  },
  data() {
    return {
      step: "get_start", // get_start
      elements: [
        {
          id: 1,
          title: "item 1",
          desc: "desc 1",
        },
        {
          id: 2,
          title: "item 2",
          desc: "desc 2",
        },
        {
          id: 3,
          title: "item 3",
          desc: "desc 3",
        },
      ],
      items: {
        core_values: [
          {
            id: "core_value_1",
            title: "core value 1",
            desc: "desc 1",
          },
          {
            id: "core_value_2",
            title: "core value 2",
            desc: "desc 2",
          },
          {
            id: "core_value_3",
            title: "core value 3",
            desc: "desc 3",
          },
        ],
        permission_to_play: [
          {
            id: "permission_to_play_1",
            title: "permission_to_play 1",
            desc: "desc 1",
          },
          {
            id: "permission_to_play_2",
            title: "permission_to_play_2",
            desc: "desc 2",
          },
          {
            id: "permission_to_play_3",
            title: "permission_to_play_3",
            desc: "desc 3",
          },
        ],
        non_essential: [
          {
            id: "non_essential_1",
            title: "non_essential 1",
            desc: "desc 1",
          },
          {
            id: "non_essential_2",
            title: "non_essential 2",
            desc: "desc 2",
          },
          {
            id: "non_essential_3",
            title: "non_essential 3",
            desc: "desc 3",
          },
        ],
        moderately_important: [
          {
            id: "moderately_important_1",
            title: "moderately_important 1",
            desc: "desc 1",
          },
          {
            id: "moderately_important_2",
            title: "moderately_important 2",
            desc: "desc 2",
          },
          {
            id: "moderately_important_3",
            title: "moderately_important 3",
            desc: "desc 3",
          },
        ],
      } as any,
      options: {
        group: "droppable",
        onEnd: () => {
          //
        },
      },
      // step: "setup_complete",
      getStartedButtonLoading: false,
      // get_start
      // defining_values_optional
      // defining_your_values
      // idefining_key_traits_optional
      // identifying_key_traits
      // setup_complete
      CUSTOM_FIELDS_ENABLED: true,
      MAX_CORE_VALUES: 6, // default: 6
      MAX_PERMISSION_TO_PLAY: 6, // default: 6
      MAX_NON_ESSENTIAL: 1, // default: 1
      cloneOldSetUp: "",
      addValueModalStatus: false,
      addValueModalLibrary: true,
      valueInputEN: "",
      valueInputTH: "",
      valueOptionalDirty: false,
      valueOptional: [] as any,
      valueDataListOptional: [] as any,
      valuesIncludedOptions: [] as any,
      valuesExcludedOptions: [] as any,
      addTraitsModalStatus: false,
      addTraitsModalLibrary: true,
      traitsInputEN: "",
      traitsInputTH: "",
      isTraitsInit: false,
      traitsOptionalDirty: false,
      traitsOptional: [] as any,
      traitsDataListOptional: [] as any,
      traitsIncludedOptions: [] as any,
      traitsExcludedOptions: [] as any,
      oldSetUpData: [
        {
          id: "111",
          label: "Culture Fit for Jan 2022 Batch",
        },
        {
          id: "222",
          label: "Culture Fit for feb 2022 Batch",
        },
        {
          id: "333",
          label: "Culture Fit for mar 2022 Batch",
        },
      ],
      selectedExistsTemplate: "",
      yourValueDisableNextBTN: true,
      keyTraitsDisableNextBTN: true,
      yourValue: {
        // your value
        achievement: 0,
        adaptability: 0,
        adventure: 0,
        aesthetics: 0,
        // key traits
        articulate: 0,
        authentic: 0,
        balanced: 0,
        careful: 0,
        compassionate: 0,
        competent: 0,
      },
      // trig: avoid lint detection
      answers: [{ id: "", value: 0 }],
      // languages required
      isLangEnEnabled: true as boolean,
      isLangThEnabled: false as boolean,
      aiSetup: null as any,
      publishSubmitStatus: true,
      // relatedTraits: {} as any,
      valuesV2Map: {} as any,
      stickyNotiStatus: false,
      stickyNotiData: {
        title: "",
        pros: "",
        cons: "",
      },
    };
  },
  computed: {
    steps(): any[] {
      return [
        {
          step: "get_start",
          name: "Step 0",
          order: 0,
          title: helpers.t("setup.valueAndTraits.Setup_your_Values"),
          visible: false,
        },
        {
          step: "select_core_values",
          name: "Step 1",
          order: 1,
          title: helpers.t("setup.valueAndTraits.Select_Core_Values"),
          desc: helpers.t(
            "setup.valueAndTraits.Identify_your_most_important_values"
          ),
        },
        {
          step: "select_permision_to_play_values",
          name: "Step 2",
          order: 2,
          title: helpers.t(
            "setup.valueAndTraits.Select_Permission_to_play_values"
          ),
          desc: helpers.t(
            "setup.valueAndTraits.Identify_your_basic_ethical_values"
          ),
        },
        {
          step: "select_non_essential_values",
          name: "Step 3",
          order: 3,
          title: helpers.t("setup.valueAndTraits.Select_Non_essential_Values"),
          desc: helpers.t(
            "setup.valueAndTraits.Identify_values_that_do_not_guide_behaviors_here"
          ),
        },
        {
          step: "review",
          name: "Step 4",
          order: 4,
          title: helpers.t("setup.valueAndTraits.Review"),
          desc: helpers.t(
            "setup.valueAndTraits.Confirm_your_value_assignments"
          ),
        },
        {
          step: "setup_complete",
          name: "Step 5",
          order: 5,
          title: helpers.t("setup.valueAndTraits.Setup_Completed"),
          visible: false,
        },

        /* verion: 1.0 */
        // {
        //   step: "defining_your_values",
        //   name: "Step 2",
        //   order: 2,
        //   title: "defining_your_values",
        //   disabled: true,
        //   _desc:
        //     "Select and manage a set of values that you would like to have available to evaluate and candidates select from.",
        // },
        // {
        //   step: "identifying_key_traits",
        //   name: "Step 3",
        //   order: 3,
        //   title: "identifying_key_traits",
        //   disabled: true,
        //   _desc:
        //     "Select and manage a set of values that you would like to have available to evaluate and candidates select from.",
        // },
        // {
        //   step: "setup_complete",
        //   name: "Step 4",
        //   order: 4,
        //   title: "setup_complete",
        //   disalbed: true,
        //   _desc:
        //     "Select and manage a set of values that you would like to have available to evaluate and candidates select from.",
        // },
      ];
    },
    isFirstStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;

      return item.order == 0;
    },
    isLastStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;
      return item.order == this.steps.length;
    },
    canPreviousStep(): boolean {
      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;
      return item.order > 0;
    },
    canNextStep(): boolean {
      const selectedTotal = this.getSelectedTotal();
      const setupTo = (val: number, max: number) => {
        return val > 0 && val <= max;
      };
      const atLeast = (val: number, min: number) => {
        return val >= min;
      };
      if (this.step == "select_core_values") {
        return setupTo(selectedTotal, this.MAX_CORE_VALUES);
      } else if (this.step == "select_permision_to_play_values") {
        return setupTo(selectedTotal, this.MAX_PERMISSION_TO_PLAY);
      } else if (this.step == "select_non_essential_values") {
        return atLeast(selectedTotal, this.MAX_NON_ESSENTIAL);
      } else if (this.step == "review") {
        return this.canPublishSubmit;
      } else if (this.step == "defining_your_values") {
        return !this.yourValueDisableNextBTN;
      } else if (this.step == "defining_values_optional") {
        return this.valuesIncludedOptions.length > 0;
      } else if (this.step == "identifying_key_traits") {
        return !this.keyTraitsDisableNextBTN;
      } else if (this.step == "idefining_key_traits_optional") {
        return this.traitsIncludedOptions.length > 0;
      }
      return !this.isLastStep;
    },
    canToggleStep(): boolean {
      return (
        _.indexOf(
          [
            "select_core_values",
            "select_permision_to_play_values",
            "select_non_essential_values",
          ],
          this.step
        ) !== -1
      );
    },
    canPublishSubmit(): boolean {
      // console.log("canPublishSubmit()");
      // console.log(this.items, "this.items");

      if (
        this.items.core_values.length < 1 ||
        this.items.core_values.length > this.MAX_CORE_VALUES
      )
        return false;

      if (
        this.items.permission_to_play.length < 1 ||
        this.items.permission_to_play.length > this.MAX_PERMISSION_TO_PLAY
      )
        return false;

      if (this.items.non_essential < this.MAX_NON_ESSENTIAL) return false;

      return true;
    },
    companyLogoUrl() {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    error() {
      return this.$store.getters["assessmentSection/error"];
    },
    loading() {
      return this.$store.getters["assessmentSection/loading"];
    },
    valuesAndTraitsLoading() {
      return this.$store.getters["aiAssistedSetup/valuesAndTraitsLoading"];
    },
    valuesAndTraits() {
      const valuesAndTraits =
        this.$store.getters["assessmentSection/valuesAndTraits"];
      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.definingYourValues &&
        valuesAndTraits.scenarios.definingYourValues.questions
      ) {
        // helpers.shuffleArray(
        //   valuesAndTraits.scenarios.definingYourValues.questions
        // );
        valuesAndTraits.scenarios.definingYourValues.questions.sort(
          (a: any, b: any) => {
            const nameA = a.title.toUpperCase(); // ignore upper and lowercase
            const nameB = b.title.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          }
        );
      }
      return valuesAndTraits;
    },
    formId() {
      return this.$store.getters["assessmentTemplate/formId"];
    },
    values() {
      return this.$store.getters["assessmentTemplate/values"];
    },
    existsTemplates() {
      return this.$store.getters["assessmentTemplate/existsTemplates"];
    },
    submitNewValueStatusBTN() {
      if (this.isLangEnEnabled && !this.valueInputEN) return false;
      if (this.isLangThEnabled && !this.valueInputTH) return false;
      return true;
    },
    submitNewTraitsStatusBTN() {
      if (this.isLangEnEnabled && !this.traitsInputEN) return false;
      if (this.isLangThEnabled && !this.traitsInputTH) return false;
      return true;
    },
    isLightColor(): any {
      const user = this.$store.getters["user/user"] || {};
      const color = user.color || "#1B1C1E";
      return helpers.isLightColor(color);
    },
  },
  created() {
    // clear values with declaration of value
    this.answers = [];
    this.$store.dispatch("user/loadCurrentUser");
  },
  mounted() {
    const formId = this.$router.currentRoute.value.query.id;
    this.checkQuestionId();
    this.load(formId);
    this.$store.dispatch("forceReady", null, { root: true });
  },
  methods: {
    backToLastPage() {
      // console.log("backToLastPage()");
      // this.$router.push({ name: "Assessments" });
    },
    gotoPreviousStep() {
      if (!this.canPreviousStep) return false;

      // special case
      if (this.step == "review") {
        this.saveReviewItems();
      } else if (this.step == "defining_values_optional") {
        this.step = "defining_your_values";
        this.closeStickyNoti();
        return;
      }

      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;

      const order = item.order - 1;
      const previousItem = _.find(this.steps, { order: order });
      if (!previousItem) return false;

      this.step = previousItem.step;
      this.closeStickyNoti();
    },
    async gotoNextStep() {
      // console.log("gotoNextStep()");
      if (this.isLastStep) return;

      // special case
      if (this.step == "select_core_values") {
        this.step = "select_permision_to_play_values";
        this.closeStickyNoti();
        return;
      } else if (this.step == "select_permision_to_play_values") {
        this.step = "select_non_essential_values";
        return;
      } else if (this.step == "select_non_essential_values") {
        this.closeStickyNoti();
        this.beforeReviewStep();
      } else if (this.step == "review") {
        this.afterReviewStep();
        this.publishSubmit();
        this.closeStickyNoti();
        return;
      } else if (this.step == "defining_values_optional") {
        this.beforeSetupDefiningValues();
        this.closeStickyNoti();
        return;
      } else if (this.step == "idefining_key_traits_optional") {
        this.beforeSetupIdefiningKeyTraits();
        this.closeStickyNoti();
        return;
      }

      const item = _.find(this.steps, { step: this.step });
      if (!item) return false;

      const order = item.order + 1;
      const nextItem = _.find(this.steps, { order: order });
      if (!nextItem) return false;

      if (this.step == "defining_your_values") {
        this.yourValueDisableNext();
        this.closeStickyNoti();
        return;
      } else if (this.step == "identifying_key_traits") {
        this.keyTraitsDisableNext();
        this.closeStickyNoti();
        return;
      }

      this.step = nextItem.step;
      this.closeStickyNoti();
    },
    noop() {
      //
    },
    activeClass() {
      return "active";
    },
    active() {
      //
    },
    tabNextStepCls(step: string) {
      let cls = "";
      const item = _.find(this.steps, { step: step });
      if (item && item.step == this.step) {
        cls = "active";
      } else {
        const currentItem = _.find(this.steps, { step: this.step });
        if (currentItem && item) {
          if (currentItem.order > item.order) {
            cls = "passed";
          }
        }
      }

      return cls;
    },
    gotoStep(step: any) {
      console.log(step, "step");
      return 1;
    },
    generateReviewItems() {
      const map = this.getStepMap();
      for (const $step in map) {
        const path = map[$step];
        const items = this.getSelectedItems($step);
        // console.log(items, path);
        if (Object.prototype.hasOwnProperty.call(this.items, path)) {
          this.items[path] = items;
        }
      }
      // ($items, path) in items
    },
    saveReviewItems() {
      // console.log(this.items, "this.items");
      // this.valuesAndTraits.scenarios.definingYourValues.questions
      const map = this.getStepMap();
      const paths: any = Object.values(map);
      for (const path in this.items) {
        const items = this.items[path];
        for (const i in items) {
          const item = items[i];
          const question = _.find(
            this.valuesAndTraits.scenarios.definingYourValues.questions,
            { id: item.id }
          );
          for (const j in paths) {
            question[paths[j]] = false;
          }
          question[path] = true;
        }
      }
    },
    beforeReviewStep() {
      // console.log("beforeReviewStep()");
      this.generateReviewItems();
    },
    afterReviewStep() {
      // console.log("afterReviewStep()");
      this.saveReviewItems();
    },
    async publishSubmit() {
      console.log("publishSubmit()");
      if (!this.publishSubmitStatus) return;

      this.publishSubmitStatus = false;

      let valuesAndTraitsV2: any = {
        version: "2.0",
        values: {
          coreValues: {},
          permissionToPlay: {},
          nonEssential: {},
          moderatelyImportant: {},
        },
      };

      // auto save values (compatbile with previos version)
      const stepMap: any = this.getStepMap();
      for (const step in stepMap) {
        const path = stepMap[step];
        const questions = this.getSelectedItems(step);

        // save option value to questions
        // current: permission_to_play: 5, non_essential: 1, moderately_important: 3
        // current: permission_to_play: 4, non_essential: 0, moderately_important: 2
        // default is `Moderately Important Values`
        let optionValue = 2;
        if (path == "core_values" || path == "permission_to_play")
          optionValue = 4;
        if (path == "non_essential") optionValue = 1;

        for (const i in questions) {
          const question = questions[i];
          const option = _.find(question.options, { value: optionValue });
          if (option) {
            // save values info for v2.0
            valuesAndTraitsV2.values[_.camelCase(path)][question.id] =
              optionValue;
            this.onClickChoice("definingYourValues", question, option);
          } else {
            console.error("Not found option for " + question.id);
          }
        }
      }

      // auto save trait to 3  (compatbile with previos version)
      this.startSetupTraits(true);
      for (const i in this.valuesAndTraits.scenarios.identifyingKeyTraits
        .questions) {
        const question =
          this.valuesAndTraits.scenarios.identifyingKeyTraits.questions[i];
        const option = _.find(question.options, { value: 3 });
        if (option) {
          this.onClickChoice("identifyingKeyTraits", question, option);
        } else {
          console.error("Not found option for " + question.id);
        }
      }

      await this.keyTraitsDisableNext({ valuesAndTraitsV2: valuesAndTraitsV2 });
      this.publishSubmitStatus = true;
    },
    getStepMap(): any {
      return {
        select_core_values: "core_values",
        select_permision_to_play_values: "permission_to_play",
        select_non_essential_values: "non_essential",
        select_moderately_important_values: "moderately_important",
      };
    },
    getPathByStep(step?: string): string {
      const map = this.getStepMap();
      if (!step) step = this.step;
      return map[step] ? map[step] : "";
    },
    toggleCheckbox(question: any) {
      const path = this.getPathByStep();
      if (!Object.prototype.hasOwnProperty.call(question, path)) {
        question[path] = false;
      }
      if (this.disabledCls(question) == "disabled") return;

      const selectedTotal = this.getSelectedTotal();
      if (this.step == "select_core_values") {
        if (selectedTotal == this.MAX_CORE_VALUES && !question[path]) return;
      } else if (this.step == "select_permision_to_play_values") {
        if (selectedTotal == this.MAX_PERMISSION_TO_PLAY && !question[path])
          return;
      }

      question[path] = !question[path];

      if (question[path]) {
        this.notifySticky(question);
      } else {
        this.closeStickyNoti();
      }
    },
    selectedCls(question: any) {
      const path = this.getPathByStep();
      if (!Object.prototype.hasOwnProperty.call(question, path)) {
        question[path] = false;
      }
      return question[path] ? "selected" : "";
    },
    disabledCls(question: any) {
      if (!this.canToggleStep) return "";

      const item = _.find(this.steps, { step: this.step });
      if (!item) return "";

      const order = item.order - 1;
      const previousItem = _.find(this.steps, { order: order });
      if (!previousItem) return "";

      if (
        previousItem.step == "select_core_values" ||
        previousItem.step == "select_permision_to_play_values"
      ) {
        let path = this.getPathByStep(previousItem.step);
        if (!Object.prototype.hasOwnProperty.call(question, path)) {
          question[path] = false;
        }
        let cls = question[path] ? "disabled" : "";

        // @fixed try to find first step
        if (
          cls == "" &&
          previousItem.step == "select_permision_to_play_values"
        ) {
          path = this.getPathByStep("select_core_values");
          if (!Object.prototype.hasOwnProperty.call(question, path)) {
            question[path] = false;
          }
          cls = question[path] ? "disabled" : "";
        }

        return cls;
      }
      return "";
    },
    getSelectedTotal(): number {
      const questions = _.get(
        this.valuesAndTraits,
        "scenarios.definingYourValues.questions"
      );
      if (!questions) return 0;

      const path = this.getPathByStep();
      if (!path) return 0;

      let filter: any = {};
      filter[path] = true;
      const count = _.filter(questions, filter).length;

      return count;
    },
    getItemStatus(question: any): string {
      const qid = question.id;
      let itemStatus = _.isObject(this.valuesV2Map[qid])
        ? this.valuesV2Map[qid].desc
        : "";
      // console.log(question.title, question, this.lang(question, "desc"));
      // if (question && this.relatedTraits[question.id]) {
      //   const titles = [];
      //   for (const i in this.relatedTraits[question.id]) {
      //     const trait = this.relatedTraits[question.id][i];
      //     if (_.isObject(trait)) {
      //       const title = this.lang(trait, "title");
      //       if (title) {
      //         titles.push(title);
      //       }
      //     }
      //   }
      //   itemStatus = titles.join(", ");
      // }

      if (
        this.step == "select_permision_to_play_values" ||
        this.step == "select_non_essential_values"
      ) {
        if (question.core_values) {
          itemStatus = helpers.t("setup.valueAndTraits.CORE_VALUE");
        } else if (question.permission_to_play) {
          if (this.step != "select_permision_to_play_values") {
            itemStatus = helpers.t("setup.valueAndTraits.PERMISSION_TO_PLAY");
          }
        }
      }
      return itemStatus;
    },
    getSelectedItems(step: any): any[] {
      const path = this.getPathByStep(step);
      if (path == "moderately_important") {
        return _.filter(
          this.valuesAndTraits.scenarios.definingYourValues.questions,
          (question) => {
            return (
              question.core_values !== true &&
              question.permission_to_play !== true &&
              question.non_essential !== true
            );
          }
        );
      } else {
        let filter: any = {};
        filter[path] = true;
        return _.filter(
          this.valuesAndTraits.scenarios.definingYourValues.questions,
          filter
        );
      }
    },
    openStickyNoti() {
      this.stickyNotiStatus = true;
    },
    closeStickyNoti() {
      this.stickyNotiStatus = false;
    },
    notifySticky(question: any) {
      // console.log("notifySticky()");
      if (!question) return;
      const qid = question.id;
      if (_.isObject(this.valuesV2Map[qid])) {
        this.stickyNotiData = {
          title: this.valuesV2Map[qid].title,
          pros: this.valuesV2Map[qid].pros || "",
          cons: this.valuesV2Map[qid].cons || "",
        };
        this.openStickyNoti();
      }
    },
    moveValue(event: any) {
      console.log("moveValue()");
      console.log(event, "event");
    },
    logValue(event: any) {
      console.log("logValue");
      console.log(event, "event");
      if (event) return;

      if (event.moved) {
        const element = event.moved.element;
        console.log(element, "element");
        const paths = [
          "core_values",
          "permission_to_play",
          "non_essential",
          "moderately_important",
        ];
        let activePath = "";
        for (const i in paths) {
          const path = paths[i];
          if (element[path] === true) {
            activePath = path;
            break;
          }
        }
        const newIndex = event.moved.newIndex;
        const oldIndex = event.moved.oldIndex;

        const map = this.getStepMap();
        let $step = "";
        for (const j in map) {
          if (map[j] == activePath) {
            $step = j;
            break;
          }
        }
        let list = this.getSelectedItems($step);

        const newObj = _.cloneDeep(list[newIndex]);
        const oldObj = _.cloneDeep(list[oldIndex]);
        console.log(`newObj: ${newObj.title}`);
        console.log(`oldObj: ${oldObj.title}`);

        const questions =
          this.valuesAndTraits.scenarios.definingYourValues.questions;
        questions[0].title = "obj can change";
        console.log(questions, "questions");
        for (const i in questions) {
          const qid = questions[i].id;
          if (qid == newObj.id) {
            console.log(`new: ${newObj.title} -> old ${questions[i].title}`);
            questions[i] = newObj;
          } else if (qid == oldObj.id) {
            console.log(`old: ${oldObj.title} -> new ${questions[i].title}`);
            questions[i] = oldObj;
          }
        }

        // @todo may be save order
      }
    },
    async load(formId: any) {
      await this.$store.dispatch(
        "assessmentTemplate/load",
        { formId: formId, section: "values_and_traits" },
        {
          root: true,
        }
      );

      // try to skip first step and auto set with ai
      if (this.$store.getters["assessmentTemplate/values"]) {
        const values = this.$store.getters["assessmentTemplate/values"];
        const path = "ai_setup.values_and_traits";
        const aiSetup = _.get(values, path);
        if (aiSetup && aiSetup.pending_review) {
          this.aiSetup = aiSetup;
          this.startSetupValues();
        }
      }
    },
    async loadSetupValuesAndTraits() {
      await this.$store.dispatch(
        "assessmentSection/loadSetupValuesAndTraits",
        null,
        {
          root: true,
        }
      );
    },
    async useSelectedExistsTemplate() {
      if (this.selectedExistsTemplate) {
        const selectedExistsTemplate = _.find(this.existsTemplates, {
          _id: this.selectedExistsTemplate,
        });
        if (selectedExistsTemplate) {
          this.getStartedButtonLoading = true;
          const formId = this.$router.currentRoute.value.query.id;
          await this.$store.dispatch(
            "assessmentTemplate/loadFromExistsTemplate",
            {
              formId: formId,
              selectedExistsTemplate: selectedExistsTemplate,
              section: "values_and_traits",
            },
            {
              root: true,
            }
          );
          this.getStartedButtonLoading = false;
        }
      }
    },
    filterMethod(query: string, item: any) {
      return item.initial.toLowerCase().includes(query.toLowerCase());
    },
    // IMPORTANT
    // TODO: managing
    onValuesElTransferChange(
      valueIds: string[],
      direction: string,
      transIds: string[]
    ) {
      if (!_.isArray(transIds)) return;
      if (direction != "left" && direction != "right") return;

      const valuesIncludedOptions = [];
      const valuesExcludedOptions = [];
      for (const i in this.valueDataListOptional) {
        const option = this.valueDataListOptional[i];
        const found = _.indexOf(valueIds, option.key) !== -1;
        if (found) {
          valuesExcludedOptions.push(option);
        } else {
          valuesIncludedOptions.push(option);
        }
      }
      this.valuesIncludedOptions = valuesIncludedOptions;
      this.valuesExcludedOptions = valuesExcludedOptions;
    },
    openValueModal(
      addValueModalStatus: boolean,
      addValueModalLibrary: boolean
    ) {
      this.addValueModalStatus = addValueModalStatus;
      this.addValueModalLibrary = addValueModalLibrary;
    },
    closeValueModal() {
      this.addValueModalStatus = false;
      (this.valueInputEN = ""), (this.valueInputTH = "");
    },
    getInputLabelValue(lang: string, labelEn: any, labelTh: any) {
      if (this.isLangEnEnabled && this.isLangThEnabled) {
        return lang == "en" ? labelEn : labelTh;
      } else {
        if (this.isLangEnEnabled) {
          return labelEn;
        } else if (this.isLangThEnabled) {
          return labelTh;
        }
      }
    },
    submitNewValue() {
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();
      if (this.submitNewValueStatusBTN) {
        // const time = "_" + Date.now();
        // const key = this.valueInputEN + time;
        const key = "custom_" + helpers.generateString(20);
        if (this.addValueModalLibrary) {
          // add to included box
          this.valueDataListOptional.push({
            key: key,
            // label: lang == "en" ? this.valueInputEN : this.valueInputTH,
            label: this.getInputLabelValue(
              lang,
              this.valueInputEN,
              this.valueInputTH
            ),
            labelEN: this.valueInputEN,
            labelTH: this.valueInputTH,
          });
        } else {
          // add to excluded box
          this.valueOptional.push(key);
          this.valueDataListOptional.push({
            key: key,
            // label: lang == "en" ? this.valueInputEN : this.valueInputTH,
            label: this.getInputLabelValue(
              lang,
              this.valueInputEN,
              this.valueInputTH
            ),
            labelEN: this.valueInputEN,
            labelTH: this.valueInputTH,
          });
        }
        const valueIds = this.valueOptional;
        const direction = this.addValueModalLibrary ? "left" : "right";
        const transIds = [key];
        this.onValuesElTransferChange(valueIds, direction, transIds);

        this.valueInputEN = "";
        this.valueInputTH = "";
        this.addValueModalStatus = false;
      }
    },
    backToStart() {
      if (this.CUSTOM_FIELDS_ENABLED) {
        this.step = "idefining_key_traits_optional";
      } else {
        this.step = "get_start";
      }
    },
    async startSetupValues() {
      // console.log("startSetupValues()");
      if (this.getStartedButtonLoading) return;
      this.getStartedButtonLoading = true;
      window.scrollTo(0, 0);
      // this.step = "idefining_key_traits_optional";
      // this.step = "defining_your_values"; // version 1.0

      const aiSetup = this.aiSetup;

      // @note used for skip custom step
      if (!this.CUSTOM_FIELDS_ENABLED) {
        this.setupDefiningValues();
        return;
      }

      if (!aiSetup) {
        await this.useSelectedExistsTemplate();
      }

      const isNew = !(this.values.values_and_traits_is_custom || false);
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();

      if (Array.isArray(this.values.languages_required)) {
        this.isLangEnEnabled =
          _.indexOf(this.values.languages_required, "English") !== -1;
        this.isLangThEnabled =
          _.indexOf(this.values.languages_required, "Thai") !== -1;
      }

      // Mark flag for value alignment version
      const valuesAndTraitsVersionId = _.get(
        this.values,
        "values_and_traits_version_id",
        null
      );
      // console.log(valuesAndTraitsVersionId, "valuesAndTraitsVersionId");
      this.$store.dispatch(
        "assessmentSection/setValuesAndTraitsVersionId",
        valuesAndTraitsVersionId,
        { root: true }
      );

      await this.loadSetupValuesAndTraits();
      const path = "scenarios.definingYourValues.questions";
      const questions = _.get(this.valuesAndTraits, path);
      let direction = "left";
      const transIds = [] as any;

      if (isNew) {
        const includedQuestions = _.map(questions, (obj) => {
          return {
            key: obj.id,
            // @todo keep in mind we must translate all questions
            // label: lang == "en" ? obj.title : obj.title_th,
            label: this.getInputLabelValue(lang, obj.title, obj.title_th),
            labelEN: obj.title,
            labelTH: obj.title_th,
          };
        });
        const excludedQuestions: string[] = [];
        this.valueDataListOptional = includedQuestions;
        this.valueOptional = excludedQuestions;
      } else {
        // console.log(this.values, "this.values");
        const customFields = this.values.values_and_traits_custom_fields;
        // console.log(customFields, "customFields");
        this.valueDataListOptional = _.concat(
          customFields.values_included_options,
          customFields.values_excluded_options || []
        );

        // because option is transformed to snake case
        // console.log(this.valueDataListOptional, "this.valueDataListOptional");
        this.valueDataListOptional = _.map(
          this.valueDataListOptional,
          (option) => {
            return {
              key: option.key,
              // label: lang == "en" ? option.labelEN : option.labelTH,
              label: this.getInputLabelValue(
                lang,
                option.labelEN,
                option.labelTH
              ),
              labelTH: option.labelTH,
              labelEN: option.labelEN,
            };
          }
        );

        const excludedQuestions = _.map(
          customFields.values_excluded_options,
          (option) => {
            return option.key;
          }
        );
        this.valueOptional = excludedQuestions;
        direction = "right";
      }

      if (aiSetup) {
        this.valueOptional = [];
        const questionsMap: any = {};
        for (const i in this.valueDataListOptional) {
          const question = this.valueDataListOptional[i];
          const id = question.key;

          let key = _.snakeCase(question.labelEN);

          // @fixed typo (valid to invalid)
          if (key == "perserverance") {
            key = "perseverance";
          }

          if (!Object.prototype.hasOwnProperty.call(aiSetup.values, key)) {
            this.valueOptional.push(id);
          }

          questionsMap[key] = id;
        }
      }

      const valueIds = this.valueOptional;
      this.onValuesElTransferChange(valueIds, direction, transIds);

      if (aiSetup) {
        const defining_your_values: any = {};
        for (const i in this.valuesIncludedOptions) {
          let label = _.snakeCase(this.valuesIncludedOptions[i].labelEN);
          if (label == "perserverance") {
            label = "perseverance";
          }
          const value = Object.prototype.hasOwnProperty.call(
            aiSetup.values,
            label
          )
            ? aiSetup.values[label]
            : null;
          const key = this.valuesIncludedOptions[i].key;
          defining_your_values[key] = Number(value);
        }
        if (!this.values.values_and_traits) {
          this.values.values_and_traits = {};
        }
        this.values.values_and_traits.defining_your_values =
          defining_your_values;
      }

      // IMPORTANT: set traits for each value
      // console.log(this.valuesAndTraits, "this.valuesAndTraits");
      /*
      for (const i in this.valuesAndTraits.scenarios.identifyingKeyTraits
        .questions) {
        const question =
          this.valuesAndTraits.scenarios.identifyingKeyTraits.questions[i];
        if (question && question.related_to) {
          if (!this.relatedTraits[question.related_to])
            this.relatedTraits[question.related_to] = [];
          this.relatedTraits[question.related_to].push(question);
        }
      }
      //*/

      // set item status (global map, prevent caching non update value)
      // valuesV2Map
      for (const i in this.valuesAndTraits.scenarios.definingYourValues
        .questions) {
        const question =
          this.valuesAndTraits.scenarios.definingYourValues.questions[i];
        this.valuesV2Map[question.id] = {
          title: this.lang(question, "title"),
          desc: this.lang(question, "desc"),
          pros: this.lang(question, "pros"),
          cons: this.lang(question, "cons"),
        };
      }

      if (!isNew) {
        this.beforeSetupDefiningValues();

        try {
          const values: any = _.get(
            this.values,
            "values_and_traits_v_2.values"
          );
          if (values) {
            for (const path in values) {
              const map = values[path];
              for (const qid in map) {
                const question: any = _.find(
                  this.valuesAndTraits.scenarios.definingYourValues.questions,
                  { id: qid }
                );
                if (question) {
                  question[path] = true;
                }
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      // @fixed @test quick test
      // console.log("@fixed quick test: definingYourValues");
      // for (const i in questions) {
      //   this.onClickChoice(
      //     "definingYourValues",
      //     questions[i],
      //     questions[i].options[2]
      //   );
      // }

      this.getStartedButtonLoading = false;
      this.step = "select_core_values";
    },
    // called by next button of defining_values_optional step
    beforeSetupDefiningValues() {
      console.log("beforeSetupDefiningValues()");
      // console.log(this.valueDataListOptional, "this.valueDataListOptional");
      if (this.valueDataListOptional.length > 0) {
        window.scrollTo(0, 0);

        // the easiest way of current dev is keep valuesAndTraits
        // modify only questions of it

        if (this.CUSTOM_FIELDS_ENABLED) {
          // const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();

          // mapping new questions
          const questions = [];
          for (const i in this.valuesIncludedOptions) {
            const option = this.valuesIncludedOptions[i];
            const question = {
              checked: false,
              dirty: false,
              id: option.key,
              name: option.label,
              options: [
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" },
              ],
              order: 0,
              title: option.labelEN ? option.labelEN : option.labelTH,
              title_th: option.labelTH ? option.labelTH : option.labelEN,
              // title: this.getInputLabelValue(
              //   "en",
              //   option.labelEN,
              //   option.labelTH
              // ),
              // title_th: this.getInputLabelValue(
              //   "th",
              //   option.labelEN,
              //   option.labelTH
              // ),
              type: "choice",
              value: "",
            };
            questions.push(question);
          }
          const valuesAndTraits = this.valuesAndTraits;
          valuesAndTraits.scenarios.definingYourValues.questions = questions;
          this.$store.commit(
            "assessmentSection/valuesAndTraits",
            valuesAndTraits,
            { root: true }
          );
          this.step = "defining_your_values";
        } else {
          // @todo in the future check process is it compatible
          this.setupDefiningValues();
        }

        // EDIT Mode
        // Default only same setting. Dont' know what's first action or back button to edit
        if (
          this.values.values_and_traits &&
          this.values.values_and_traits.defining_your_values
        ) {
          if (!this.valueOptionalDirty) {
            const allIds = _.map(this.valuesIncludedOptions, (option) => {
              return option.key;
            }).sort();
            const selectedValues =
              this.values.values_and_traits.defining_your_values;
            const selectedIds = Object.keys(selectedValues).sort();
            // console.log(allIds, "allIds");
            // console.log(selectedIds, "selectedIds");
            // console.log(
            //   this.valuesIncludedOptions,
            //   "this.valuesIncludedOptions"
            // );
            // console.log(
            //   this.values.values_and_traits.defining_your_values,
            //   "this.values.values_and_traits.defining_your_values"
            // );
            const canDefault = allIds.join(".") == selectedIds.join(".");
            if (canDefault) {
              for (const id in selectedValues) {
                const value =
                  this.values.values_and_traits.defining_your_values[id];
                const path = "definingYourValues";
                const question = { id: id };
                const choice = { value: value };
                this.onClickChoice(path, question, choice);
              }
            }
            this.valueOptionalDirty = true;
          }

          // process to check yourValueDisableNextBTN
          const questions =
            _.get(
              this.valuesAndTraits,
              "scenarios.definingYourValues.questions"
            ) || [];
          let yourValueDisableNextBTN = false;
          for (const i in questions) {
            const question = questions[i];
            const options = questions[i].options || [];
            let found = false;
            for (const j in options) {
              const option = options[j];
              const selected = this.isSelectedChoiceCls(
                "definingYourValues",
                question,
                option
              );
              if (selected === true) {
                found = true;
                break;
              }
            }
            if (!found) {
              yourValueDisableNextBTN = true;
              break;
            }
          }
          this.yourValueDisableNextBTN = yourValueDisableNextBTN;
        }
      }
    },
    async setupDefiningValues() {
      await this.useSelectedExistsTemplate();
      this.step = "defining_your_values";
      !this.valuesAndTraits && (await this.loadSetupValuesAndTraits());
      if (this.valuesAndTraits) {
        const hasOwnProperty = Object.prototype.hasOwnProperty;
        if (
          this.values &&
          hasOwnProperty.call(this.values, "values_and_traits")
        ) {
          const history = this.values.values_and_traits;
          try {
            for (const key in this.valuesAndTraits.scenarios) {
              const scenario = this.valuesAndTraits.scenarios[key];
              const scenarioKey = _.snakeCase(key);
              for (const i in scenario.questions) {
                const question = scenario.questions[i];
                const questionId = question.id;
                const hasScenario = hasOwnProperty.call(history, scenarioKey);
                if (hasScenario) {
                  const hasValue = hasOwnProperty.call(
                    history[scenarioKey],
                    questionId
                  );
                  if (hasValue) {
                    const value = history[scenarioKey][questionId];
                    const option = _.find(question.options, { value: value });
                    if (!option) {
                      const errorMsg = `Not found option value ${value} on scenario order ${scenario.order}`;
                      console.log(errorMsg);
                      continue;
                    }
                    this.onClickChoice(key, question, option);
                  }
                }
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
    },
    // IMPORTANT
    // TODO: managing
    onTraitsElTransferChange(
      valueIds: string[],
      direction: string,
      transIds: string[]
    ) {
      if (!_.isArray(transIds)) return;
      if (direction != "left" && direction != "right") return;

      // describe include and exclude (not include) values
      const traitsIncludedOptions = [];
      const traitsExcludedOptions = [];
      for (const i in this.traitsDataListOptional) {
        const option = this.traitsDataListOptional[i];
        const found = _.indexOf(valueIds, option.key) !== -1;
        if (found) {
          traitsExcludedOptions.push(option);
        } else {
          traitsIncludedOptions.push(option);
        }
      }

      this.traitsIncludedOptions = traitsIncludedOptions;
      this.traitsExcludedOptions = traitsExcludedOptions;
    },
    submitNewTraits() {
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();
      if (this.submitNewTraitsStatusBTN) {
        // const time = "_" + Date.now();
        const key = "custom_" + helpers.generateString(20);
        if (this.addTraitsModalLibrary) {
          // add to included box
          this.traitsDataListOptional.push({
            key: key,
            // label: lang == "en" ? this.traitsInputEN : this.traitsInputTH,
            label: this.getInputLabelValue(
              lang,
              this.traitsInputEN,
              this.traitsInputTH
            ),
            labelEN: this.traitsInputEN,
            labelTH: this.traitsInputTH,
          });
        } else {
          // add to excluded box
          this.traitsOptional.push(key);
          this.traitsDataListOptional.push({
            key: key,
            // label: lang == "en" ? this.traitsInputEN : this.traitsInputTH,
            label: this.getInputLabelValue(
              lang,
              this.traitsInputEN,
              this.traitsInputTH
            ),
            labelEN: this.traitsInputEN,
            labelTH: this.traitsInputTH,
          });
        }

        const valueIds = this.traitsOptional;
        const direction = this.addTraitsModalLibrary ? "left" : "right";
        const transIds = [key];
        this.onTraitsElTransferChange(valueIds, direction, transIds);

        this.traitsInputEN = "";
        this.traitsInputTH = "";
        this.addTraitsModalStatus = false;
      }
    },
    startSetupTraits(doNotChangeStep = false) {
      console.log("startSetupTraits()");
      window.scrollTo(0, 0);
      // this.step = "idefining_key_traits_optional";
      if (!doNotChangeStep) this.step = "identifying_key_traits";
      if (!this.CUSTOM_FIELDS_ENABLED) {
        // this.step = "identifying_key_traits";
        return;
      }

      if (this.isTraitsInit) return;

      let direction = "left";
      const transIds = [] as any;

      const isNew = !(this.values.values_and_traits_is_custom || false);
      const lang = ((this as any).$i18n.getLocale(0) || "en").toLowerCase();
      if (isNew) {
        const path = "scenarios.identifyingKeyTraits.questions";
        const questions = _.get(this.valuesAndTraits, path);
        const includedQuestions = _.map(questions, (obj) => {
          return {
            key: obj.id,
            // @todo keep in mind we must translate all questions
            // label: lang == "en" ? obj.title : obj.title_th,
            label: this.getInputLabelValue(lang, obj.title, obj.title_th),
            labelEN: obj.title,
            labelTH: obj.title_th,
          };
        });
        const excludedQuestions: string[] = [];
        this.traitsDataListOptional = includedQuestions;
        this.traitsOptional = excludedQuestions;
      } else {
        const customFields = this.values.values_and_traits_custom_fields;
        this.traitsDataListOptional = _.concat(
          customFields.traits_included_options,
          customFields.traits_excluded_options || []
        );

        // because option is transformed to snake case
        this.traitsDataListOptional = _.map(
          this.traitsDataListOptional,
          (option) => {
            return {
              key: option.key,
              // label: lang == "en" ? option.labelEN : option.labelTH,
              label: this.getInputLabelValue(
                lang,
                option.labelEN,
                option.labelTH
              ),
              labelTH: option.labelTH,
              labelEN: option.labelEN,
            };
          }
        );

        const excludedQuestions = _.map(
          customFields.traits_excluded_options,
          (option) => {
            return option.key;
          }
        );
        this.traitsOptional = excludedQuestions;
        direction = "right";
      }

      const aiSetup = this.aiSetup;
      if (aiSetup) {
        this.traitsOptional = [];
        const questionsMap: any = {};
        for (const i in this.traitsDataListOptional) {
          const question = this.traitsDataListOptional[i];
          const id = question.key;
          const key = _.snakeCase(question.labelEN);
          if (!Object.prototype.hasOwnProperty.call(aiSetup.traits, key)) {
            this.traitsOptional.push(id);
          }
          questionsMap[key] = id;
        }
      }

      const valueIds = this.traitsOptional;
      this.onTraitsElTransferChange(valueIds, direction, transIds);

      if (!isNew) {
        this.beforeSetupIdefiningKeyTraits(doNotChangeStep);
      }

      // console.log(
      //   this.values.values_and_traits,
      //   "this.values.values_and_traits"
      // );
      if (aiSetup) {
        const identifying_key_traits: any = {};
        for (const i in this.traitsIncludedOptions) {
          const label = _.snakeCase(this.traitsIncludedOptions[i].labelEN);
          const value = Object.prototype.hasOwnProperty.call(
            aiSetup.traits,
            label
          )
            ? aiSetup.traits[label]
            : null;
          const key = this.traitsIncludedOptions[i].key;
          // console.log(label, value, key);
          identifying_key_traits[key] = Number(value);
        }
        this.values.values_and_traits.identifying_key_traits =
          identifying_key_traits;
      }

      this.isTraitsInit = true;

      // @fixed quick test: identifyingKeyTraits
      // console.log("@fixed quick test: identifyingKeyTraits");
      // console.log(this.valuesAndTraits, "this.valuesAndTraits");
      // for (const i in this.valuesAndTraits.scenarios.identifyingKeyTraits
      //   .questions) {
      //   const question =
      //     this.valuesAndTraits.scenarios.identifyingKeyTraits.questions[i];
      //   this.onClickChoice(
      //     "identifyingKeyTraits",
      //     question,
      //     question.options[4]
      //   );
      // }
    },
    beforeSetupIdefiningKeyTraits(doNotChangeStep = false) {
      if (this.traitsIncludedOptions.length > 0) {
        window.scrollTo(0, 0);

        if (this.CUSTOM_FIELDS_ENABLED) {
          // mapping new questions
          const questions = [];
          for (const i in this.traitsIncludedOptions) {
            const option = this.traitsIncludedOptions[i];
            const question = {
              checked: false,
              dirty: false,
              id: option.key,
              name: option.label,
              options: [
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" },
              ],
              order: 0,
              // title: option.labelEN,
              // title_th: option.labelTH,
              title: this.getInputLabelValue(
                "en",
                option.labelEN,
                option.labelTH
              ),
              title_th: this.getInputLabelValue(
                "th",
                option.labelEN,
                option.labelTH
              ),
              type: "choice",
              value: "",
            };
            questions.push(question);
          }
          const valuesAndTraits = this.valuesAndTraits;
          valuesAndTraits.scenarios.identifyingKeyTraits.questions = questions;
          this.$store.commit(
            "assessmentSection/valuesAndTraits",
            valuesAndTraits,
            { root: true }
          );
        }

        // EDIT Mode
        // Default only same setting. Dont' know what's first action or back button to edit
        if (
          this.values.values_and_traits &&
          this.values.values_and_traits.identifying_key_traits
        ) {
          if (!this.traitsOptionalDirty) {
            const allIds = _.map(this.traitsIncludedOptions, (option) => {
              return option.key;
            }).sort();
            const selectedValues =
              this.values.values_and_traits.identifying_key_traits;
            const selectedIds = Object.keys(selectedValues).sort();
            const canDefault = allIds.join(".") == selectedIds.join(".");
            if (canDefault) {
              for (const id in selectedValues) {
                const value =
                  this.values.values_and_traits.identifying_key_traits[id];
                const path = "identifyingKeyTraits";
                const question = { id: id };
                const choice = { value: value };
                this.onClickChoice(path, question, choice);
              }
            }
            this.traitsOptionalDirty = true;
          }

          // process to check keyTraitsDisableNextBTN
          const questions =
            _.get(
              this.valuesAndTraits,
              "scenarios.identifyingKeyTraits.questions"
            ) || [];
          let keyTraitsDisableNextBTN = false;
          for (const i in questions) {
            const question = questions[i];
            const options = questions[i].options || [];
            let found = false;
            for (const j in options) {
              const option = options[j];
              const selected = this.isSelectedChoiceCls(
                "identifyingKeyTraits",
                question,
                option
              );
              if (selected === true) {
                found = true;
                break;
              }
            }
            if (!found) {
              keyTraitsDisableNextBTN = true;
              break;
            }
          }
          this.keyTraitsDisableNextBTN = keyTraitsDisableNextBTN;
        }

        if (!doNotChangeStep) this.step = "identifying_key_traits";
      }
    },
    checkQuestionId() {
      const id = this.$router.currentRoute.value.query.id;
      if (!id) {
        ElMessage.error((this as any).$t("popup.message.message_16e"));
        setTimeout(() => {
          this.$router.push({ name: "pageNotFound404" });
        }, 1000);
      }
    },
    closeTab() {
      window.close();
    },
    sortTraitsByValues() {
      // @see https://trello.com/c/c3OAjDA8/1339-assessment-as-a-company-i-want-to-be-able-to-configure-my-values-traits-from-a-preset-list-so-that-i-can-easily-identify-items-t
      const scenarios = this.valuesAndTraits.scenarios;
      const valuesQuestions = scenarios.definingYourValues.questions;

      // because we can press previos button for edit values
      // so answers have total more than real total
      const valuesQuestionIds: string[] = [];
      for (const i in valuesQuestions) {
        valuesQuestionIds.push(valuesQuestions[i].id);
      }
      const answersMap: any = {};
      for (const i in this.answers) {
        answersMap[this.answers[i].id] = this.answers[i].value;
      }

      // can i easily sort related traits here?
      // simple is the best! sort here
      // scenarios.identifyingKeyTraits.questions.reverse();
      scenarios.identifyingKeyTraits.questions = _.sortBy(
        scenarios.identifyingKeyTraits.questions,
        [
          (question: any) => {
            if (question.related_to) {
              if (_.indexOf(valuesQuestionIds, question.related_to) !== -1) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    answersMap,
                    question.related_to
                  )
                ) {
                  const value = answersMap[question.related_to];
                  question.sortValue = value;
                  return value;
                }
              }
            } else {
              console.log("Not found related_to", question);
            }
            return -1;
          },
        ]
      ).reverse();
    },
    yourValueDisableNext() {
      // console.log("yourValueDisableNext()");
      if (!this.yourValueDisableNextBTN) {
        this.sortTraitsByValues();
        window.scrollTo(0, 0);
        // this.step = "identifying_key_traits";
        this.startSetupTraits();
      }
    },
    async keyTraitsDisableNext(additonalData?: any) {
      console.log("keyTraitsDisableNext()");
      this.$store.commit("assessmentSection/loading", true);
      let sectionAnswersDto: any = {
        _id: this.$router.currentRoute.value.query.id,
        section: this.valuesAndTraits,
        answers: this.answers,
        callback: () => {
          if (this.aiSetup) {
            this.$store.dispatch(
              "aiAssistedSetup/unsetup",
              "values_and_traits",
              {
                root: true,
              }
            );
          }
          this.$store.commit("assessmentSection/loading", false);
          this.step = "setup_complete";
        },
      };

      if (this.CUSTOM_FIELDS_ENABLED) {
        sectionAnswersDto.valuesAndTraitsIsCustom = true;
        sectionAnswersDto.valuesAndTraitsCustomFields = {
          valuesIncludedOptions: this.valuesIncludedOptions,
          valuesExcludedOptions: this.valuesExcludedOptions,
          traitsIncludedOptions: this.traitsIncludedOptions,
          traitsExcludedOptions: this.traitsExcludedOptions,
        };

        // assessmentTemplate/values
        if (_.has(this.values, "values_and_traits_version_id")) {
          sectionAnswersDto.valuesAndTraitsVersionId = _.get(
            this.values,
            "values_and_traits_version_id"
          );
        }
      }

      // console.log(sectionAnswersDto, "sectionAnswersDto");
      // alert("debug");
      // if (this.$store) return;

      if (_.isObject(additonalData)) {
        _.merge(sectionAnswersDto, additonalData);
      }

      await this.$store.dispatch(
        "assessmentTemplate/saveSectionAnswers",
        sectionAnswersDto,
        {
          root: true,
        }
      );
    },
    barWidht(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      return `width: ${pergressPercentage.toFixed(1)}%`;
    },
    fontColor(progress: number, candidates: number) {
      const pergressPercentage = (progress / candidates) * 100;
      if (+pergressPercentage.toFixed(1) > 50) {
        return true;
      }
      return false;
    },
    onClickChoice(path: string, question: any, choice: any) {
      const answer = { id: String(question.id), value: Number(choice.value) };
      const index = _.findIndex(this.answers, { id: answer.id });
      if (index === -1) {
        this.answers.push(answer);
      } else {
        this.answers[index].value = answer.value;
      }

      let questionIds = [];
      try {
        questionIds = this.valuesAndTraits.scenarios[path].questions.map(
          (q: any) => {
            return q.id;
          }
        );
      } catch (e) {
        console.error(e);
      }

      // handle state of "yourValueDisableNextBTN"
      const size = questionIds.length;
      let count = 0;
      let done = false;
      for (const index in this.answers) {
        if (_.indexOf(questionIds, this.answers[index].id) !== -1) {
          count++;
        }
        if (count == size) {
          done = true;
          break;
        }
      }
      if (path == "definingYourValues") {
        this.yourValueDisableNextBTN = !done;
      } else if (path == "identifyingKeyTraits") {
        this.keyTraitsDisableNextBTN = !done;
      }
    },
    isSelectedChoiceCls(_path: string, question: any, choice: any) {
      const index = _.findIndex(this.answers, {
        id: String(question.id),
        value: Number(choice.value),
      });
      return index !== -1;
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      return helpers.objectLang(obj, attr);
    },
  },
});
